<template>
  <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 라인별 설비 목록 -->
      <c-table
        ref="tableImpr"
        title="LBL0003696"
        :columns="gridImpr.columns"
        :editable="editable && !disabled"
        :data="data.dailyImproveModelList"
        :merge="gridImpr.merge"
        :usePaging="false"
        :isExcelDown="false"
        :filtering="false"
        :columnSetting="false"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customCol'">
            <component
              :is="imprComponent"
              :col="col"
              :props="props"
              :inputEditable="editable&&!disabled"
              :isImmShow="true"
              :requestContentsCols="requestContentsCols"
              :tableKeys="tableKeys"
              ibmTaskTypeCd="ITT0000070"
              ibmTaskUnderTypeCd="ITTU000120"
              @imprChange="imprChange"
            />
          </template>
          
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
export default {
  name: 'equipment-daily-impr',
  props: {
    data: {
      type: Object,
      default: () => ({
        dailyCheckId: '',  // 열화점검 일련 번호
        checkStatusCd: '',
        dailyName: '',
        utlityFlag: '',
        dailyImproveModelList: [], 
      }),
    },
  },
  data() {
    return {
      editable: true,
      gridImpr: {
        columns: [
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            // 설비관리번호
            label: 'LBL0000631',
            align: 'left',
            style: 'width:30%',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            // 설비명
            label: 'LBL0000632',
            align: 'left',
            style: 'width:30%',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL00001562',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:40%',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ],
        
        data: [],
        height: '200px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        popupParam: {},
        closeCallback: null,
      },
      isSave: false,
      checkCdItems: [], // 검사결과
      getUrl: '',
      items: [],
      getStdUrl: '',
      insertUrl: '',
      deleteUrl: '',
      colorItems: [],
      component: null,
    };
    
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId)
    },
    // 테이블 키 멀티
    tableKeys() {
      return ['equipmentCd','dailyCheckId'];
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['equipmentName'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
  },
  methods: {
    init() {
    },
    // 개선창 닫기 후
    imprChange() {
      this.$emit('getDetail');
      this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>